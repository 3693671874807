
import { IonPage, IonContent, loadingController, IonSpinner, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent, IonHeader, IonTitle, IonToolbar, IonBackButton, IonImg } from '@ionic/vue';
import { defineComponent, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Empty from '../../../components/Empty.vue';
/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: { IonPage, IonContent, IonSpinner, Empty, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent, IonHeader, IonTitle, IonToolbar, IonBackButton, IonImg },
  setup(){
    const router = useRouter();
    const state = reactive({
      user:{
        data: {
          id: 0
        },
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1
      },
      contentLoading: true,
      isScrollDisabled: false,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      if ( state.user.data.id == 0 ) {
        await services.authUser().then(
          (response) => {
            const { data } = response;
            state.user.data = data;
            state.user.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
          }
        );
      }


      const params = {
        size: state.user.size,
        page: state.user.page
      }

      await services.blockOne(state.user.data.id, params).then(
        response => {
          const { data } = response;
          if ( event ) {
            if ( event.type == "ion-refresh" ) {
              state.user.items = [];
            }
          }

          data.items.some((item: object) => {
            state.user.items.push(item);
          });

          state.user.items = data.items;
          state.user.totalItems = data.totalItems;
          state.user.totalPages = data.totalPages;
          state.user.loading = false;
          state.user.page++;
          if ( event ) {
            if ( data.items.length == 0 && loadMore ) {
              state.isScrollDisabled = true;
            }
          }
        },
        error => {
          let message = '';
          try {
            message = error.response.data.message;
          } catch (e) {
            message = '';
            console.log(e);
          }
          console.log(message);
          console.log(message);
          state.user.loading = false;
          if ( event ) {
            if ( event.type == "ion-refresh" ) {
              state.user.items = [];
            }
          }
          state.isScrollDisabled = true;
        }
      );

      setTimeout(()=>{
        if ( event ) {
          event.target.complete();
        }

        state.contentLoading = false
      } ,500)
    }

    const onUnBlock = async (index: number, item: any) => {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: '잠시 기다려주세요.',
      });
      await loading.present();

      const params = {
        userId: item.id
      };

      services.blockRemove(params).then(
        response => {
          if(response.status == 204){
            item.block = 0;
            state.user.items.splice(index, 1);
          }
          loading.dismiss();
        },
        error => {
          console.log(error);
          loading.dismiss();
        }
      )
    }

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500)
    };

    const onUserLink = (id: number) => {
      /*
      router.push({
        name: "UserId",
        params: {id: id}
      })
      */
    }

    // 새로고침
    const onRefresh = (event: any) => {
      state.user.page = 1;
      state.user.loading = true;
      state.isScrollDisabled = false;
      fetch(event);
    }

    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push('/')
    }

    // onMounted
    onMounted(() => {
      state.user.items = [];
      fetch();
      console.log('onMounted');
    })

    return { state, onUserLink, onClose, onUnBlock, onLoadData, onRefresh }
  }
})
